<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-brawl-stars-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">خرید ربات: بررسی و پرداخت</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2 class="text-lg font-bold text-center p-3 bg-sky-500 text-sky-50">
          سفارش :{{ pay.id }}
        </h2>
        <div>
          <div class="p-2 font-bold text-center odd:bg-blueGray-100">
            {{ pay.description }}
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">مدت</div>
            <div class="font-bold text-left">{{ pay.info_json.month }} ماه</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">نوع اشتراک</div>
            <div class="font-bold text-left">{{ list_type[pay.info_json.type] }}</div>
          </div>
          <div class="p-2 text-center font-bold text-xl bg-sky-500 text-sky-50">
            {{ $number.format(pay.amount) }} تومان
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            @click="payByCharge(false)"
            v-if="$store.state.user?.admin?.is_admin"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
            :disabled="disabaledForm"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان (ادمین)
          </Button>
          <Button
            v-else
            :href="apiUrl + 'api/go-pay/' + pay.token"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان
          </Button>
        </div>
        <div
          class="flex-1 mx-1"
          v-if="
            pay.amount <=
            $store.state.user.wallet +
              $store.state.user.earn +
              $store.state.user.bonus -
              $store.state.user.debit
          "
        >
          <Button
            @click="payByCharge"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="teal"
            :disabled="disabaledForm"
          >
            پرداخت از شارژ
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import _ from "lodash";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      disabaledForm: false,
      sending: false,
      group: {},
      pay: {},
      apiUrl: process.env.VUE_APP_PAY_URL,
      L_: _,
      list_type: {
        green: "سبز",
        blue: "آبی",
        bronze: "برنزی",
        silver: "نقره‌ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(
          "/api/group-buy-bot/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.payId,
          {
            params: {
              id: $this.$store.state.user.id,
              // date: $this.date,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.pay = response.data.pay;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    payByCharge(charge = true) {
      // console.log("p b a");
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;
      $this.$axios
        .put(
          "/api/group-buy-bot/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.payId,
          {
            id: $this.$store.state.user.id,
            charge: charge,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.$router.push(response.data.goTo);
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>

<style></style>
